<template>
  <section>
    <div class="box w-full" style="overflow:hidden;">
      <div
      id="grafica"
      ref="elGrafica"
      style="height:550px;"
      class="w-full"
      />
    </div>
  </section>
</template>
<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import InformesRipsService from '../services'
import { useStore } from 'vuex'
// import axios from 'axios'
import currency from 'currency.js'
// import { messageWarning } from '../../../../../../libs/mensajes'

export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const _informesRipsService = new InformesRipsService()
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    watch(props.filters, (filter) => {
      if (filter.year !== '') {
        fetchPacientes(filter)
      }
    })
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          top: 15,
          left: 15,
          text: 'Top 10 Pacientes'
        },
        grid: {
          left: 15,
          right: 15,
          bottom: 50,
          top: 85,
          containLabel: true
        },
        toolbox: {
          top: 15,
          right: 15
          // feature: {
          //   myTool1: {
          //       show: true,
          //       right: 15,
          //       top: 10,
          //       title: 'Detallado',
          //       icon: 'image:///images/excel.png',
          //       onclick: () => {
          //         descargarExcel()
          //       }
          //   },
          //   saveAsImage: {}
          // }
        },
        tooltip: {
          top: 0,
          formatter (a) {
            return `${a.name} <br> ${currency(a.value, { precision: 0, separator: '.' }).format()}`
          }
        },
        legend: {
          show: false,
          data: ['Pacientes']
        },
        yAxis: [
          {
            show: false
          }
        ],
        xAxis: {
          data: getChartLabels(),
          axisLabel: {
            interval: 0,
            fontSize: 10,
            width: 60,
            overflow: 'break'
          }
        },
        series: [
          {
            name: 'Pacientes',
            type: 'bar',
            showBackground: true,
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              formatter (d) {
                return `${currency(d.value / 1000000, { precision: 0, separator: '.' }).format()}M`
              },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          data: getChartLabels(),
          show: true
        },
        series: [
          {
            showBackground: true,
            name: 'Pacientes',
            type: 'bar',
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              formatter (d) {
                return `${currency(d.value / 1000, { precision: 0, separator: '.' }).format()}.Mil`
              },
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }

    const getChartData = () => {
      return chartData.value.map(a => a.Valor)
    }

    const getChartLabels = () => {
      return chartData.value.map(a => a.fullName)
    }

    const setChartData = (values = []) => {
      chartData.value = values
    }
    const fetchPacientes = (filters) => {
      _informesRipsService.apiTopPacientes(filters).then(({ data, status }) => {
        setChartData(data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      })
    }

    // const descargarExcel = () => {
    //   if (!props.filters.client) {
    //     messageWarning('Por favor seleccione un cliente para generar el reporte en excel')
    //     return false
    //   }
    //   const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report/top-pacientes-xls`
    //   const res = axios.getUri({ url, params: props.filters })
    //   window.open(res, '_blank')
    // }

    onMounted(() => {
      initChart()
    })

    return {
      // descargarExcel,
      chart,
      elGrafica,
      _informesRipsService
    }
  }
}
</script>

<style>

</style>
